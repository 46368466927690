<!--
 * @Author: zhangguoliang
 * @Date: 2021-11-22 17:12:10
 * @LastEditors: wuxinxin
 * @LastEditTime: 2022-04-18 14:26:18
-->
<template>
  <div class="bg0">
    <div class="content-wrap">
      <img class="bg1" src="@/assets/img/h5.png" />
    </div>
    <div class="apply-button" @click="applyUse"></div>
    <div class="dialogShow">
      <van-dialog
        v-model="show"
        :showConfirmButton="false"
        class="applyDialog"
        :close-on-click-overlay="true"
      >
        <div class="log0">
          <div class="title">立即体验</div>
          <van-form>
            <div class="filed">
              <div class="per items">
                <label>联系人:</label>
                <van-field
                  v-model="realName"
                  class="contact"
                  maxlength="10"
                  placeholder="请输入联系人"
                  :error="false"
                  :rules="[{ required: true, message: '' }]"
                />
              </div>
              <div class="per items">
                <label>联系电话:</label>
                <van-field
                  v-model="mobile"
                  class="telNumber"
                  type="tel"
                  maxlength="11"
                  placeholder="请输入联系电话"
                  :error="false"
                  :rules="[{ required: true, message: '' }]"
                />
              </div>
              <div class="explain items">
                <label>验证码:</label>
                <van-field
                  v-model="mobileVerifyCode"
                  class="contact"
                  maxlength="6"
                  type="tel"
                  placeholder="请输入验证码"
                />
                <button
                  class="code"
                  @click="start"
                  type="text"
                  :disabled="codeDisabled"
                >
                  {{ codeMsg }}
                </button>
              </div>
              <div class="tel items">
                <label>申请说明:</label>
                <van-field
                  v-model="applyExplain"
                  class="telNumber"
                  type="text"
                  maxlength="100"
                />
              </div>
            </div>
            <van-button
              round
              block
              type="info"
              native-type="submit"
              class="submit"
              :loading="loading"
              @click="onSubmit"
              >确认
            </van-button>
          </van-form>
        </div>
        <template name="footer">
          <div
            v-show="this.deleteShow"
            class="closedialog"
            @click="close"
          ></div>
        </template>
      </van-dialog>
    </div>
  </div>
</template>
<script>
import Vue from 'vue';
import { Notify } from 'vant';
import 'vant/lib/button/style';
import * as Api from '@/api/tryOut.ts';
import VConsole from 'vconsole';
export default Vue.extend({
  data() {
    return {
      show: false,
      realName: '',
      mobile: '',
      mobileVerifyCode: '',
      applyExplain: '',
      codeDisabled: true,
      codeMsg: '获取验证码',
      countdown: 60,
      timer: null,
      telShow: false,
      contactShow: false,
      deleteShow: false,
      loading: false,
      docmHeight: document.documentElement.clientHeight, //默认屏幕高度
      showHeight: document.documentElement.clientHeight, //实时屏幕高度
    };
  },

  mounted() {
    // window.onresize监听页面高度的变化
    window.onresize = () => {
      return (() => {
        this.showHeight = document.body.clientHeight;
      })();
    };
  },
  created() {
    document.title = '云算房';
  },
  //监听
  watch: {
    showHeight: function () {
      if (this.docmHeight !== this.showHeight) {
        this.deleteShow = false;
      } else {
        this.deleteShow = true;
      }
    },
    mobile: {
      handler(newName) {
        var mobile = /^[1][3,4,5,7,8,9][0-9]{9}$/.test(newName);
        if (mobile === false && mobile !== '') {
          this.telShow = true;
        } else {
          this.codeDisabled = false;
          this.telShow = false;
        }
      },
    },
    realName: {
      handler(newName) {
        var realName = /^[a-zA-Z\u4e00-\u9fa5]{2,10}$/.test(newName);
        if (realName === false && realName !== '') {
          this.contactShow = true;
        } else {
          this.contactShow = false;
        }
      },
    },
  },
  methods: {
    applyUse() {
      this.show = true;
      this.telShow = false;
      this.deleteShow = true;
    },
    close() {
      this.show = false;
      this.deleteShow = false;
    },
    /**
     * @description: 获取h5执行时所在环境 0 web浏览器 1 抖音 2 微信
     * @param {*}
     * @return {number}
     */
    channel() {
      const ua = window.navigator.userAgent.toLowerCase();
      if (/MicroMessenger/i.test(ua)) {
        return 2;
      }
      if (/aweme/i.test(ua)) {
        return 1;
      }
      return 3;
    },
    onSubmit() {
      if (
        !this.realName ||
        !/^[a-zA-Z\u4e00-\u9fa5]{2,10}$/.test(this.realName)
      ) {
        Notify(' 请输入2-10位字母或汉字');
        return;
      }
      if (!this.mobile || !/^[1][3,4,5,7,8,9][0-9]{9}$/.test(this.mobile)) {
        Notify('请输入正确手机号');
        return;
      }
      if (this.mobileVerifyCode === '') {
        Notify('请输入验证码');
      } else {
        const param = {
          mobile: this.mobile,
          realName: this.realName,
          mobileVerifyCode: this.mobileVerifyCode,
          applyExplain: this.applyExplain,
          oauthClientId: 'SUB_YUNSUANFANG',
          oauthClientApplicationName: '云算房',
          intentionSource: this.channel(),
        };
        this.loading = true;
        Api.agenciesIntentionClient(param)
          .then((res) => {
            if (res) {
              this.loading = false;
              this.$message.success('提交成功');
              this.show = false;
              this.deleteShow = false;
              this.reset();
            } else {
              this.$message.error(res.message);
            }
          })
          .finally(() => {
            this.loading = false;
          });
      }
    },
    reset() {
      this.mobile = '';
      this.realName = '';
      this.mobileVerifyCode = '';
      this.applyExplain = '';
      clearInterval(this.timer);
      this.codeMsg = '获取验证码';
      this.countdown = 60;
      this.timer = null;
      this.codeDisabled = false;
    },
    start() {
      if (this.mobile !== '') {
        Api.mobile_smsCode(this.mobile).then((res) => {
          if (res) {
            this.codeDisabled = true;
            if (!this.timer) {
              this.timer = setInterval(() => {
                if (this.countdown > 0 && this.countdown <= 60) {
                  this.countdown--;
                  if (this.countdown !== 0) {
                    this.codeDisabled = true;
                    this.codeMsg = '重新发送(' + this.countdown + ')';
                  } else {
                    clearInterval(this.timer);
                    this.codeMsg = '获取验证码';
                    this.countdown = 60;
                    this.timer = null;
                    this.codeDisabled = false;
                  }
                }
              }, 1000);
            }
          } else {
            this.$message.error(res.message);
          }
        });
      } else {
        this.codeDisabled = false;
      }
    },
  },
});
</script>
<style lang="less">
.items {
  display: flex !important;
  align-items: center;
  margin-bottom: 20px;
  > label {
    width: 94px;
    word-break: keep-all;
    text-align: right;
    margin-right: 4px;
  }
}
</style>
<style lang="less" scoped>
.bg0 {
  width: 100%;
  height: 100vh;
  position: relative;
  .content-wrap {
    height: 100vh;
    overflow-y: auto;
    .bg1 {
      width: 100%;
    }
  }
  .apply-button {
    position: absolute;
    height: 60px;
    width: 200px;
    background-repeat: no-repeat;
    background-image: url(../assets/img/button.png);
    background-size: cover;
    border-radius: 40px;
    left: 50%;
    transform: translateX(-50%);
    bottom: 50px;
  }
  .dialogShow {
    position: relative;
  }
  .closedialog {
    width: 16px;
    height: 16px;
    position: absolute;
    top: 13px;
    right: 6px;
    opacity: 0.5;
    background-repeat: no-repeat;
    background-image: url(../assets/img/newdelete.png);
    background-size: cover;
    z-index: 3000 !important;
  }
  .applyDialog {
    width: 93%;
    height: 444px;
    background-repeat: no-repeat;
    background-image: url(../assets/img/apply.png);
    background-size: cover;
    z-index: 4000 !important;
    background-color: #0749c0;
  }
  .log0 {
    width: 90%;
    height: 370px;
    border-radius: 10px;
    border: 1px solid #00fbe9;
    position: absolute;
    top: 50%;
    margin-top: -185px;
    margin-left: 15px;
  }
  .submit {
    width: 140px;
    height: 44px;
    line-height: 44px;
    position: absolute;
    top: 83%;
    left: 50%;
    transform: translateX(-50%);
    text-align: center;
    border-image: linear-gradient(rgba(0, 255, 236, 1), rgba(0, 255, 247, 0.56))
      2 2;
    background: linear-gradient(180deg, #3563ff 0%, #0a00ae 100%);
    box-shadow: 0px 2px 17px 0px rgba(0, 0, 0, 0.08);
    border-radius: 34px;
    border: 1px solid #00fbe9;
    font-size: 20px;
  }
  .title {
    width: 100%;
    font-size: 23px;
    font-weight: 400;
    color: #00fbe9;
    line-height: 54px;
    text-align: center;
    margin-top: 20px;
  }
  .filed {
    position: relative;
    margin-top: 30px;
    padding: 0 8px 0 0;
  }
}
.per {
  display: inline-block;
  font-size: 17px;
  color: #00ffe9;
}
.tel {
  display: inline-block;
  font-size: 17px;
  color: #00ffe9;
}
.telTip {
  position: absolute;
  font-size: 14px;
  color: #ee5365;
  margin-left: 75px;
  margin-bottom: -15px;
}
.contactTip {
  position: absolute;
  font-size: 14px;
  color: #ee5365;
  margin-left: 62px;
  margin-bottom: -15px;
}
.code {
  position: absolute;
  width: 110px;
  left: 63%;
  bottom: 1px;
  display: inline-block;
  font-size: 15px;
  color: #00ffe9;
  background-color: #b8b9b900;
  border: 1px rgba(245, 243, 243, 0) solid;
}
.time {
  position: absolute;
  width: 100px;
  left: 77%;
  top: 28px;
  display: inline-block;
  font-size: 14px;
  color: #cbcecd;
  background-color: #b8b9b900;
  border: 1px rgba(0, 0, 0, 0) solid;
}
.explain {
  position: relative;
  display: inline-block;
  font-size: 17px;
  color: #00ffe9;
}
.contact {
  display: inline-block;
  border-bottom: 1px #00ffe9 solid;
  background-color: rgba(0, 0, 0, 0);
}
.number {
  border: 1px rgba(0, 0, 0, 0) solid;
  border-bottom: 1px #00ffe9 solid;
  background-color: rgba(0, 0, 0, 0);
}
.telNumber {
  display: inline-block;
  border-bottom: 1px #00ffe9 solid;
  background-color: rgba(0, 0, 0, 0);
}
</style>
<style>
.van-dialog {
  border-radius: 12px;
}
.van-cell {
  position: relative;
  font-size: 20px;
  color: #00ffe9;
  padding: 0px;
  flex: 1;
}
.van-field__control {
  font-size: 16px;
  color: #ffffff;
}
.van-cell::after {
  border-bottom: 1px #f9fafa00 solid;
}
.van-field--error .van-field__control,
.van-field--error .van-field__control::placeholder {
  color: #ee5365;
  -webkit-text-fill-color: currentColor;
}
.ant-message {
  z-index: 3000 !important;
}
</style>
